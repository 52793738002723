import i18n from '@/lang'
export default class DatePickerLang {
  public days: string[] = [
    String(i18n.t('common.date.sun')),
    String(i18n.t('common.date.mon')),
    String(i18n.t('common.date.tue')),
    String(i18n.t('common.date.wed')),
    String(i18n.t('common.date.thu')),
    String(i18n.t('common.date.fri')),
    String(i18n.t('common.date.sat')),
  ]
  public months: string[] = [
    String(i18n.t('common.date.january')),
    String(i18n.t('common.date.february')),
    String(i18n.t('common.date.march')),
    String(i18n.t('common.date.april')),
    String(i18n.t('common.date.may')),
    String(i18n.t('common.date.june')),
    String(i18n.t('common.date.july')),
    String(i18n.t('common.date.august')),
    String(i18n.t('common.date.september')),
    String(i18n.t('common.date.october')),
    String(i18n.t('common.date.november')),
    String(i18n.t('common.date.december'))
  ]
}
